import Error from "@@/common/Error.vue";
import cont_match_reg from "@/utils/editor.js";
import { getProjectContent } from "@api/index";
import { observeVideo } from "md-base-tools/editor/gitvideo";
export default {
  components: {
    Error
  },
  data() {
    return {
      showError: false,
      showMessage: "",
      content: ''
    };
  },
  async mounted() {
    const pro_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    const {
      data,
      status,
      message
    } = await getProjectContent({
      pro_id: pro_id
    });
    if (status == 0) {
      const {
        product_info
      } = data;
      this.content = product_info ? product_info.content : '';
    } else if (status == '999999') {
      this.showError = true;
      this.showMessage = "网络错误";
      this.sensors.track('ProjectClientDetail', {
        Type: "网络错误"
      });
    } else {
      this.showError = true;
      this.showMessage = message || "什么都没有哦";
      this.sensors.track('ProjectClientDetail', {
        Type: "接口错误",
        Message: message
      });
    }
    const _this = this;
    _this.$nextTick(() => {
      if (!_this.showError && _this.$refs.editorBox) {
        _this.$refs.editorBox.appendChild(cont_match_reg(_this.content));
        _this.$nextTick(() => {
          observeVideo(_this.$refs.editorBox, process.env.VUE_APP_ENV);
        });
      }
    });
  }
};